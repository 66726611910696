import React from 'react'

const Footer = props => {
    return (
        <footer>
            <img alt="logo" src="https://i.imgur.com/PVWYGD4.png?4"></img>    
        </footer>
    )
}

export default Footer